<template>
  <router-view ></router-view>
</template>


<script>
// import PartnerTable from "@/views/partner/PartnerTable.vue"
export default {
  name: "partners",
  // components: {PartnerTable},
  data: () => ({}),
  methods: {},
};
</script>

<style scoped>
</style>